import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

export const GetStarted = () => {

    return (
        <React.Fragment>
            <Helmet>

            </Helmet>

            <section className="overflow-hidden md:h-screen w-full">
                <div className="flex md:flex-row flex-col justify-start w-full h-full relative">
                    <div className="md:absolute w-full md:top-0 md:left-0 h-full md:z-20 bg-gray-100 dark:bg-gray-800 md:bg-inherit">
                        <div className="flex items-center h-full w-full py-4 container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl mx-auto">
                            <div className="w-full md:basis-1/2 h-full flex flex-row-reverse items-center md:py-10 py-5">
                                <div className="w-full">
                                    <span className="text-lg font-bold text-orange-600 dark:text-orange-400">
                                        Getting Started
                                    </span>

                                    <h2 className="mt-12 mb-10 text-5xl font-extrabold leading-tight text-gray-800 dark:text-gray-200">
                                        Launch Your Journey with Ease
                                    </h2>

                                    <p className="mb-16 text-lg text-gray-600 dark:text-gray-400">
                                        Sign up today and start connecting with your audience!
                                    </p>

                                    <Link to="/auth/sign-up" target="_blank" className="inline-block animate-bounce px-12 py-4 text-white font-bold bg-orange-600 hover:bg-orange-700 rounded-full shadow-lg transition duration-200">
                                        Get Started
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:basis-1/2 hidden bg-gray-100 dark:bg-gray-800 h-full md:flex flex-row-reverse items-center py-10 px-20 text-end"></div>

                    <div className="md:basis-1/2 md:block w-full py-40 md:h-screen z-20 get-started slide-layout">
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}