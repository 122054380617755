import React from "react"
import { Helmet } from "react-helmet"

import { Header } from "./Header"
import { Footer } from "./Footer"

export const FAQ = () => {

    return (
        <React.Fragment>
            <Helmet>
                <title>FAQs</title>
            </Helmet>

            <div className="flex h-screen w-full">
                <Header />

                <div className="flex-grow w-full relative isolate">
                    <div className="bg-orange-200 md:col-span-4 pb-8 text-gray-700 pt-[85px]">
                        <div className="container mx-auto md:text-xl pt-4 md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                            <h3 className="mt-4 mb-4 text-4xl sm:text-5xl leading-normal font-extrabold tracking-tight">
                                Frequently Asked <span className="text-orange-600">Questions</span>
                            </h3>

                            <div className="flex items-center mt-5">
                                <span className="text-sm">
                                    Quick answers to the most frequently asked questions to help you get the information you need.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="pb-4 px-6 md:px-0 max-w-screen-sm mx-auto pt-4">
                        <div className="w-full">
                            <div className="flex items-start my-8">
                                <div
                                    className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                    <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g data-name="Layer 2">
                                            <g data-name="menu-arrow">
                                                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                <path
                                                    d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                </path>
                                                <circle cx="12" cy="19" r="1"></circle>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div className="text-md">
                                    <h1 className="text-gray-900 font-semibold mb-2 flex items-center">
                                        <div className="md:hidden flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                            <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <g data-name="Layer 2">
                                                    <g data-name="menu-arrow">
                                                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                        <path
                                                            d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                        </path>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        What is Bigfan?
                                    </h1>

                                    <p className="text-gray-500 mb-3">
                                        Bigfan is a revolutionary platform that uplifts creatives - musicians, DJs, actors, content creators, influencers, and event organizers. It enables direct appreciation from their audience, transforming each creation into a powerful moment of connection. .
                                    </p>

                                    <p className="text-gray-500">
                                        Through Bigfan, fans can instantaneously express their gratitude, contributing funds and personalized messages directly to the creators. This platform fosters a culture where creativity is not only recognized and valued but also celebrated
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-start my-8">
                                <div
                                    className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                    <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g data-name="Layer 2">
                                            <g data-name="menu-arrow">
                                                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                <path
                                                    d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                </path>
                                                <circle cx="12" cy="19" r="1"></circle>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div className="text-md">
                                    <h1 className="text-gray-900 font-semibold mb-2 flex items-center">
                                        <div className="md:hidden flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                            <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <g data-name="Layer 2">
                                                    <g data-name="menu-arrow">
                                                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                        <path
                                                            d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                        </path>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        How do I sign up?
                                    </h1>

                                    <p className="text-gray-500">
                                        Big fan provides two methods of sign up - your Google account, or use your preferred email and password. Simply input all the necessary credentials, including your official names and stage name/alias. Upon account verification, download your personalized QR code to share with your fans.
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-start my-8">
                                <div
                                    className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                    <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g data-name="Layer 2">
                                            <g data-name="menu-arrow">
                                                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                <path
                                                    d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                </path>
                                                <circle cx="12" cy="19" r="1"></circle>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div className="text-md">
                                    <h1 className="text-gray-900 font-semibold mb-2 flex items-center">
                                        <div className="md:hidden flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                            <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <g data-name="Layer 2">
                                                    <g data-name="menu-arrow">
                                                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                        <path
                                                            d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                        </path>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        Do I have to be a creator to sign up?
                                    </h1>

                                    <p className="text-gray-500">
                                        <span className="font-bold">Yes,</span> Bigfan caters to various categories, including Performing Arts(Musicians, Actors & Comedians), Content Creators and Influencers, Event Organizers. If you fit into any of these dynamic fields, you are eligible to join the Bigfan community.
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-start my-8">
                                <div
                                    className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                    <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g data-name="Layer 2">
                                            <g data-name="menu-arrow">
                                                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                <path
                                                    d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                </path>
                                                <circle cx="12" cy="19" r="1"></circle>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div className="text-md">
                                    <h1 className="text-gray-900 font-semibold mb-2 flex items-center">
                                        <div className="md:hidden flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                            <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <g data-name="Layer 2">
                                                    <g data-name="menu-arrow">
                                                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                        <path
                                                            d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                        </path>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        How do I withdraw my money?
                                    </h1>

                                    <p className="text-gray-500">
                                        When you're logged into your creator portal, click on the <span className="text-orange-600">Withdraw</span> button, input the desired amount you'd love to withdraw and watch as the funds swiftly hit your Mpesa account.
                                    </p>
                                </div>
                            </div>

                            <div className="flex items-start my-8">
                                <div
                                    className="hidden sm:flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                    <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g data-name="Layer 2">
                                            <g data-name="menu-arrow">
                                                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                <path
                                                    d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                </path>
                                                <circle cx="12" cy="19" r="1"></circle>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div className="text-md">
                                    <h1 className="text-gray-900 font-semibold mb-2 flex items-center">
                                        <div className="md:hidden flex items-center justify-center p-3 mr-3 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                            <svg width="24px" fill="white" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <g data-name="Layer 2">
                                                    <g data-name="menu-arrow">
                                                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                                                        <path
                                                            d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z">
                                                        </path>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        How long does account verification take?
                                    </h1>

                                    <p className="text-gray-500">
                                        Account verification typically takes 48 hours. Keep a close watch on your mailbox for updates.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />

                </div>
            </div>
        </React.Fragment>
    )
}