import React from "react"
import { Helmet } from "react-helmet"
import { Header } from "./Header"
import { Footer } from "./Footer"

export const AboutUs = () => {

    return (
        <React.Fragment>
            <Helmet>
                <title>About Us</title>
            </Helmet>

            <div className="flex h-screen w-full">
                <Header />

                <div className="flex-grow w-full relative isolate">
                    <div className="bg-orange-200 md:col-span-4 pb-8 text-gray-700 pt-[85px]">
                        <div className="container mx-auto md:text-xl pt-4 md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                            <h3 className="mt-4 mb-4 text-4xl sm:text-5xl leading-normal font-extrabold tracking-tight">
                                About <span className="text-orange-600">Us</span>
                            </h3>
                        </div>
                    </div>

                    <section className="overflow-hidden w-full container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl mx-auto">
                        <div className="flex items-center h-full w-full py-4 ">
                            <div className="w-full px-3 py-5">
                                <div className="mx-auto container md:text-xl text-base md:max-w-4xl lg:max-w-4xl xl:max-w-5xl pb-5">
                                    <div className="sm:flex flex items-center">
                                        <div className="px-4">
                                            <div className="text-base">
                                                <h1 className="mt-2 text-2xl font-bold tracking-tight text-orange-600 sm:text-3xl">
                                                    Welcome to Big Fan!
                                                </h1>

                                                <p className="mt-6 leading-8 text-gray-700">
                                                    Established in 2024 by committed industry players, we are dedicated to empowering creators by fostering fan support. With a passion for transparency, we provide artists with a platform to cultivate their craft driven by fans.
                                                </p>

                                                <p className="mt-6 leading-8 text-gray-700">
                                                    At Big Fan, we believe in the transformative power of appreciation and aim to create a thriving community where creativity flourishes. Join us in celebrating and appreciate the talented creatives who inspire us every day.
                                                </p>

                                                <p className="mt-6 leading-8 text-gray-700">
                                                    At Big Fan, we strive to bridge the gap between creators and their fans, cultivating a deep sense of connection and mutual appreciation.
                                                </p>

                                                <p className="mt-6 leading-8 text-gray-700">
                                                    Our aim is to champion emerging talents and established creatives alike, providing them with the tools and support they need to thrive in their creative journey.
                                                </p>

                                                <p className="mt-6 leading-8 text-gray-700">
                                                    By fostering a culture of collaboration and recognition, we aim to create a vibrant ecosystem where artists feel valued and inspired to push the boundaries of their art. Join us in our commitment to nurturing creativity and celebrating the diverse talents that make our world a more beautiful place.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />

                </div>
            </div>
        </React.Fragment>
    )
}