import { Helmet } from "react-helmet"
import React, {  } from "react"

import { Header } from "./Header"
import { Footer } from "../ingress/Footer"

export const ContactUs = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>

            <div className="flex h-screen w-full">
                <Header />

                <div className="flex-grow w-full relative isolate">
                    <div className="bg-orange-200 md:col-span-4 pb-8 text-gray-700 pt-[85px]">
                        <div className="container mx-auto md:text-xl pt-4 md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                            <h3 className="mt-4 mb-4 text-4xl sm:text-5xl leading-normal font-extrabold tracking-tight">
                                Contact <span className="text-orange-600">Us</span>
                            </h3>
                            <p className="mt-4 leading-7 text-gray-700 text-sm">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                industry's standard dummy text ever since the 1500s.
                            </p>

                            <div className="flex items-center mt-5">
                                <span className="text-sm">House #14, Street #12, Darulaman Road, Kabul, Afghanistan.</span>
                            </div>
                        </div>
                    </div>

                    <section className="overflow-hidden md:h-screen w-full">
                        <div className="flex md:flex-row flex-col justify-start w-full h-full relative bg-gray-100">
                            <div className="md:absolute w-full md:top-0 md:left-0 h-full md:z-20 bg-gray-100 dark:bg-gray-800 md:bg-inherit">
                                <div className="flex items-center h-full w-full py-4 container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl mx-auto">
                                    <div className="w-full md:basis-1/2 h-full flex flex-row-reverse items-center md:py-10 py-5">
                                        <div className="w-full">
                                            <div className="mb-12 flex w-full flex-col text-center">
                                                <h1 className="title-font mb-4 text-orange-600 sm:text-4xl text-5xl font-semibold leading-tight">
                                                    Contact Us
                                                </h1>

                                                <p className="mx-auto text-xl leading-relaxed dark:text-gray-200">
                                                    Feel free to reach out to us! Whether you have a question,
                                                    feedback, or a collaboration proposal, we'd love to hear from you.
                                                </p>
                                            </div>

                                            <div className="mx-auto w-full">
                                                <div className="-m-2 flex flex-col flex-wrap">
                                                    <div className="w-full flex flex-wrap">
                                                        <div className="w-1/2 p-2">
                                                            <div className="relative">
                                                                <input type="text" id="name" name="name" className="peer w-full rounded border border-gray-400 dark:border-gray-600 bg-white dark:bg-gray-900 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100  placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-orange-500 focus:ring-0" placeholder="Name" />
                                                                <label htmlFor="name" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-orange-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-orange-500">Name</label>
                                                            </div>
                                                        </div>

                                                        <div className="w-1/2 p-2">
                                                            <div className="relative">
                                                                <input type="email" id="email" name="email" className="peer w-full rounded border border-gray-400 dark:border-gray-600 bg-white dark:bg-gray-900 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-orange-500 focus:ring-0" placeholder="Email" />
                                                                <label htmlFor="email" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-orange-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-orange-500">Email</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-4 w-full p-2">
                                                        <div className="relative">
                                                            <textarea id="message" name="message" className="peer h-32 w-full resize-none rounded border border-gray-400 dark:border-gray-600 bg-white dark:bg-gray-900 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-orange-500 focus:ring-0" placeholder="Message"></textarea>
                                                            <label htmlFor="message" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-orange-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-orange-500">Message</label>
                                                        </div>
                                                    </div>

                                                    <div className="w-full p-2 pt-8">
                                                        <button className="inline-block animate-bounce px-8 py-2.5 text-white font-bold bg-orange-600 hover:bg-orange-700 rounded-md shadow-lg transition duration-200">
                                                            Send Message
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:basis-1/2 hidden bg-gray-100 dark:bg-gray-800 h-full md:flex flex-row-reverse items-center py-10 px-20 text-end"></div>

                            <div className="md:basis-1/2 md:block w-full py-40 md:h-screen z-20 potrait slide-layout">
                            </div>
                        </div>
                    </section>

                    <Footer />

                </div>
            </div>
        </React.Fragment>
    )
}