import { Link } from "react-router-dom";
import React, { FC, useState } from "react";

import { AccountSubHeader } from "./AccountSubHeader"
import { STYLE } from "../../global/ConstantsRegistry"
import colorfulLogo from "../../assets/images/colorful_logo_only.svg"

interface headerProps {
    errorMode?: boolean,
    showSettings?: boolean,
}

export const Header: FC<headerProps> = ({ showSettings = true, errorMode = false }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    return (
        <React.Fragment>
            <nav className="bg-white shadow fixed w-full z-10 flex items-center justify-center">
                <div className="w-full mx-auto md:px-4 px-2" style={STYLE.MAX_WIDTH}>
                    <div className="relative flex items-center justify-between h-16">
                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            <Link to={'/home'} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white mb-0 font-medium px-0 py-2" aria-controls="mobile-menu" aria-expanded={isMenuOpen ? 'true' : 'false'}>
                                <img className="h-12" src={colorfulLogo} loading="lazy" alt="colorful_logo" />
                            </Link>
                        </div>

                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="hidden sm:block">
                                <div className="flex space-x-4">
                                    <Link to="/home" className="mb-0 font-medium px-0 rounded-md py-2" aria-current="page">
                                        <img className="h-16" src={colorfulLogo} loading="lazy" alt="colorful_logo" />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <div className="ml-3 relative">
                                <div>

                                    <AccountSubHeader errorMode={errorMode} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}